import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import I18nProvider from './providers/I18nProvider';
import { store } from './store.js';
import App from './components/App';
import { appConfig } from './config';

import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/main.css';

export const history = createBrowserHistory();

if (appConfig.instrumentationKey && appConfig.endpointUrl) {
    console.log('AI');
    const reactPlugin = new ReactPlugin();

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: appConfig.instrumentationKey,
            endpointUrl: appConfig.endpointUrl,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history },
            },
        },
    });

    appInsights.loadAppInsights();
}

// render the main component
ReactDOM.render(
    <Provider store={store}>
        <I18nProvider>
            <Router history={history}>
                <Route path="/" component={App} />
            </Router>
        </I18nProvider>
    </Provider>,
    document.getElementById('bokamera-embedded-configurator')
);

import React from 'react';
import { FormGroup, FormFeedback } from 'reactstrap';

class Input_ extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            type,
            meta: { error, warning, touched },
            ...props
        } = this.props;

        let message;
        if (touched && (error || warning)) {
            message = <span className="help-block">{error || warning}</span>;
        }

        return (
            <FormGroup>
                <input {...input} type={type} {...props} id={input.name} />{' '}
                <label htmlFor={input.name}>{label}</label>
                {feedbackIcon ? <FormFeedback>{feedbackIcon}</FormFeedback> : null}
                {message}
            </FormGroup>
        );
    }
}

export default Input_;

import React from 'react'
import classNames from 'classnames/bind';
import styles from './Obfuscator.module.css';

const cx = classNames.bind(styles);

export default function Obfuscator({ children, obfuscate = true, reason = "" }) {
    const layerOfBlurClass = obfuscate ? cx('layer-of-blur') : '';

    return (
        <div className={cx('obfuscator')}>
            <div
                aria-label={obfuscate ? 'Hidden code' : undefined}
                className={layerOfBlurClass}
            >
                {React.Children.map(children, child =>
                    React.cloneElement(child)
                )}
            </div>
            {obfuscate && reason ? <div className={cx('reason')}>
                <span
                    className={cx('reason__text')}
                    dangerouslySetInnerHTML={{__html: reason}}
                />
            </div> : null}
        </div>
    )
}

import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

class Input_ extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            type,
            meta: { error, warning, touched },
            ...props
        } = this.props;

        let message;

        if (touched && (error || warning)) {
            message = <span className="help-block text-danger">{error || warning}</span>;
        }

        return (
            <FormGroup>
                {label && <Label>{label}</Label>}
                <Input {...input} type={type} {...props} />
                {feedbackIcon ? <FormFeedback>{feedbackIcon}</FormFeedback> : null}
                {message}
            </FormGroup>
        );
    }
}

// export the connected class

export default Input_;

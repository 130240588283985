export default {
    about: ['Om', 'About'],
    address: ['Adress', 'Address'],
    authenticationLoading: ['Autentiserar, var god dröj', 'Authentication, please wait'],
    back: ['Förgående', 'Back'],
    book: ['Boka', 'Book'],
    bookANewTime: ['Boka ny tid', 'Book a new time'],
    bookingAgreement: ['Bokningsavtal', 'Booking agreement'],
    bookingConfirmation: ['Bokningsbekräftelse', 'Booking confirmation'],
    bookingMustBePaidBefore: ['Bokningen måste betalas före', 'Booking must be paid before'],
    bookTheTime: ['Boka tiden', 'Book the time'],
    cancel: ['Ångra', 'Cancel'],
    cancelBefore: ['Avboka senast', 'Cancel before'],
    confirmPassword: ['Upprepa lösenord', 'Confirm password'],
    createAccount: ['Skapa konto', 'Create account'],
    duration: ['Längd', 'Duration'],
    email: ['E-post', 'Email'],
    finish: ['Slutför', 'Finish'],
    firstname: ['Förnamn', 'Firstname'],
    forgotYourPassword: ['Glömt lösenordet?', 'Forgot your password?'],
    from: ['Från', 'From'],
    fullyBooked: ['Fullbokat', 'Fully booked'],
    lastname: ['Efternamn', 'Lastname'],
    loadingServices: ['Hämtar tjänster', 'Loading services'],
    loadingTimes: ['Hämtar tider', 'Loading times'],
    logIn: ['Logga in', 'Log in'],
    logOut: ['logga ut', 'log out'],
    missing: ['Saknas', 'Missing'],
    next: ['Nästa', 'Next'],
    nextAvailableTime: ['Nästa lediga tid', 'Next available time'],
    noAvailableResources: ['Det saknas valbara resurser', 'No available resources'],
    noConfiguration: ['Inget företag är konfigurerat!', 'No company is configured!'],
    noSpotsFoundFor: ['Inga lediga tider hittades den', 'No spots found for'],
    noTimes: ['Inga tider', 'No times'],
    notPaid: ['Ej betald', 'Not paid'],
    number: ['Antal', 'Number'],
    numberOfSpots: ['Antal plats(er)', 'Number of spots'],
    password: ['Lösenord', 'Password'],
    payNow: ['Betala nu', 'Pay now'],
    phone: ['Telefonnummer', 'Phone'],
    price: ['Pris', 'Price'],
    print: ['Skriv ut', 'Print'],
    quarter: ['Kvartal', 'Quarter'],
    readMore: ['Läs mer', 'Read more'],
    select: ['Välj', 'Select'],
    selectDate: ['Välj ett datum', 'Select a date'],
    selectedAutomatically: ['Väljs automatiskt', 'Selected automatically'],
    selectNumber: ['Välj antal', 'Select number'],
    selectNumberOfSpots: ['Välj antal platser', 'Select number of spots'],
    selectPrice: ['Välj pris', 'Select price'],
    selectTime: ['Välj en tid', 'Select a time'],
    sendNewPassword: ['Skicka nytt lösenord', 'Send new password'],
    service: ['Tjänst', 'Service'],
    showAgreement: ['Visa bokningsavtal', 'Show booking agreement'],
    showDay: ['Visa dag', 'Show day'],
    showLess: ['visa mindre', 'show less'],
    showMore: ['visa mer', 'show more'],
    signInWithFacebook: ['Logga in med Facebook', 'Sign in with Facebook'],
    Spots: ['Platser', 'Spots'],
    spots: ['platser', 'spots'],
    status: ['Status', 'Status'],
    successfullyPaid: ['Betalt och klart!', 'Successfully paid!'],
    time: ['Tid', 'Time'],
    totally: ['Totalt', 'Totally'],
    week: ['Vecka', 'Week'],
    youAreLoggedInAs: ['Du är inloggad som', 'You are logged in as'],
    youHavePaidTo: ['Du har betalat till ', 'You have paid to'],
    yourBookingMustBePaid: ['Din bokning måste betalas', 'Your booking must be paid'],
    agreementText: [
        `
            När du klickar på boka tiden så godkänner du samtidigt bokningsavtalet.
            Vid bokning ingår du avtal direkt med den som utför tjänsten. BokaMera
            tar således inget ansvar för felaktiga eller uteblivna bokningar.
        `,
        `
            When you click on Book time you agree to the booking agreement.
            When booking you will contract directly with the service provider.
            BokaMera takes no responsibility for incorrect or missing bookings.
        `,
    ],
    bookingHasFee: [
        'Denna bokning har en avgift som måste betalas innan',
        'This booking has a fee to be paid before',
    ],
    bookingHasFee1: [
        'Denna bokning har en avgift som måste betalas.',
        'This reservation has a fee to be paid.',
    ],
    // ???
    yourBookingIsPaid: [
        'Din bokning med nummer ${bookingId} är nu betald,',
        'Your booking with number ${bookingId} is now paid,',
    ],
    instructionsHasBeenSentToEmail: [
        `
            Betalningsinstruktioner har skickats till din e-postadress.
            Du kan även välja att betala direkt genom att klicka på knappen bredvid.
        `,
        `
            Payment instructions have been sent to your email address.
            You can also choose to pay directly by clicking button next to it.
        `,
    ],
    bookNewTimePleaseWait: [
        'Bokar din tid. Var god vänta.',
        'We are booking your time. Please wait.',
    ],
    loadingAdditionalInformation: [
        'Hämtar kompletterande uppgifter',
        'Retrieving additional information',
    ],
    doYouHaveAccount: [
        'Har du redan ett konto? Klicka på logga in så slipper du fylla i alla uppgifter.',
        "Do you already have an account? Log in so you won't have to fill in all the information.",
    ],
    yourConfirmationSentTo: [
        'du kommer få en bekräftelse på din bokning skickat till',
        'you will receive a confirmation of your booking sent to',
    ],
    quantityLimitExceeded: [
        'Du har angivit ett större antal än vad som är tillgängligt för denna tiden.',
        'You have entered a larger amount than is available for this time.',
    ],
    instructionSentToYourEmail: [
        'Instruktioner för att återställa ditt lösenord har skickats till din e-postadress.',
        'Instructions for recovering your password have been sent to your email address.',
    ],
};

import React, { useCallback, useState, useRef } from 'react';
import { FormGroup, Button, Input } from 'reactstrap';
import TextareaAutosize from 'react-autosize-textarea';
import { Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';

function CopyToClipboard({ value = '', multiline = false, ...restProps }) {
    const [copied, setCopied] = useState(false);
    const inputEl = useRef(null);

    const handleCopy = useCallback(
        (e) => {
            copy(value);
            setCopied(true);
            inputEl.current.select();
        },
        [value]
    );

    const handleBlur = useCallback(() => {
        setCopied(false);
    });

    return (
        <>
            <FormGroup {...restProps}>
                {multiline ? (
                    <TextareaAutosize
                        readOnly
                        value={value}
                        className="form-control"
                        onBlur={handleBlur}
                        ref={inputEl}
                        rows={25}
                    />
                ) : (
                    <Input readOnly value={value} onBlur={handleBlur} innerRef={inputEl} />
                )}
            </FormGroup>
            <FormGroup>
                <Button type="submit" onClick={handleCopy} disabled={copied}>
                    {copied ? <Trans>Kopierat!</Trans> : <Trans>Kopiera till urklipp</Trans>}
                </Button>
            </FormGroup>
        </>
    );
}

export default CopyToClipboard;

import React, { useContext, useState, useCallback } from 'react';
import { I18nProvider as LinguiProvider, I18n as LinguiContextConsumer } from '@lingui/react';
import { I18n } from '@lingui/core';
import catalogSv from '../locales/sv/messages.js';
import catalogEn from '../locales/en/messages.js';
import catalogNo from '../locales/no/messages.js';
import catalogFi from '../locales/fi/messages.js';

const catalogs = {
    sv: catalogSv,
    en: catalogEn,
    no: catalogNo,
    fi: catalogFi,
};

export const I18nContext = React.createContext(undefined);

export const useI18n = () => useContext(I18nContext);

const I18nProvider = ({ children, defaultLanguage = 'en' }) => {
    const [language, setLanguage] = useState(
        window.localStorage?.getItem('language') || defaultLanguage
    );

    const handleSetLanguage = useCallback((language) => {
        localStorage.setItem('language', language);
        setLanguage(language);
    }, []);

    return (
        <LinguiProvider language={language} catalogs={catalogs}>
            <LinguiContextConsumer>
                {({ i18n }) => (
                    <I18nContext.Provider
                        value={{ ...i18n, language, setLanguage: handleSetLanguage }}
                    >
                        {children}
                    </I18nContext.Provider>
                )}
            </LinguiContextConsumer>
        </LinguiProvider>
    );
};

export default I18nProvider;

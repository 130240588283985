import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import classNames from 'classnames/bind';

import Configurator from './Configurator';
import Previewer from './Previewer';

import 'react-modal-video/css/modal-video.min.css';
import styles from './App.module.css';

const cx = classNames.bind(styles);

const App = (props) => {
    return (
        <div className={cx('app')}>
            <div className={cx('sidebar')}>
                <Configurator />
            </div>
            <div className={cx('content')}>
                <Previewer />
            </div>
        </div>
    );
};

export default App;

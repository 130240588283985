import React from 'react';
import Select from 'react-select';
import { Trans } from '@lingui/macro';
import { useI18n } from '../providers/I18nProvider';

export default function LanguageSelect() {
    const { language, setLanguage } = useI18n();

    const languageList = [
        { label: <Trans>English</Trans>, value: 'en' },
        { label: <Trans>Svenska</Trans>, value: 'sv' },
        { label: <Trans>Suomi</Trans>, value: 'fi' },
        { label: <Trans>Norsk</Trans>, value: 'no' }
    ];

    const handleLangugeChange = (val) => {
        setLanguage(val.value)
    }

    const activeLanguageOption = languageList.filter(lang => lang.value === language);

    return (
        <Select
            name="langSelect"
            value={activeLanguageOption}
            onChange={handleLangugeChange}
            options={languageList}
        />
    )
}

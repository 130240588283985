import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { UncontrolledPopover } from 'reactstrap';
import classNames from 'classnames/bind';
import styles from './ColorPicker.module.css';

const cx = classNames.bind(styles);

const ColorPicker = ({ id = 'color-picker', color = '#007bff', onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div id={id} className={cx('color-circle')} style={{ backgroundColor: color }} />
            <UncontrolledPopover target={id} trigger="hover">
                <SketchPicker color={color} onChange={onChange}></SketchPicker>
            </UncontrolledPopover>
        </>
    );
};

export default ColorPicker;

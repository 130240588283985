import 'unfetch';

const DEFAULT_TIMEOUT = 30000;

export function get(service, body = undefined, authentication = undefined) {
    return request('GET', service, body, authentication);
}

export function post(service, body = undefined, authentication = undefined) {
    return request('POST', service, body, authentication);
}

export function put(service, body = undefined, authentication = undefined) {
    return request('PUT', service, body, authentication);
}

export function remove(service, body = undefined, authentication = undefined) {
    return request('DELETE', service, body, authentication);
}

export function request(method, service, body, authentication) {
    let url = `${process.env.REACT_APP_API_BASE_URL}${service}`;
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': '53EECA6F-9EDA-49CF-8832-7DEF10E355CF',
    };

    if (authentication && authentication.isLoggedIn) {
        headers['x-ss-id'] = authentication.data.SessionId;
        headers['Authorization'] = 'Bearer ' + authentication.data.BearerToken;
    }

    console.debug(method + ' -> ' + url);

    return timeout(
        fetch(url, {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body, null, 4) : null,
        }),
        DEFAULT_TIMEOUT
    ).then(response => {
        if (response.ok) {
            return response.json().then(body => {
                console.debug(JSON.stringify(body, null, 4));

                return body;
            });
        } else {
            console.debug(response);

            // Automatically logged out
            if (response.status === 401) {
                console.debug('Automatically logged out');

                global.auth = null;
            }

            const status = response.status;

            return response
                .json()
                .then(body => {
                    let error = 'Ett okänt fel uppstod';

                    if (body.ResponseStatus) {
                        error = body.ResponseStatus.Message;
                    }

                    let errorObject = { _error: error };

                    if (Array.isArray(body.ResponseStatus.Errors)) {
                        body.ResponseStatus.Errors.forEach(err => {
                            errorObject[err.FieldName] = err.Message;
                        });
                    }

                    throw errorObject;
                })
                .catch(error => {
                    if (!error._error) {
                        // If not an error produced from the above code.
                        let errorObject = { _error: error.message };
                        throw errorObject;
                    } else {
                        throw error;
                    }
                });
        }
    });
}

function timeout(promise, ms) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => reject(new Error('Begäran tog för lång tid')), ms);

        promise
            .then(response => {
                clearTimeout(timer);
                resolve(response);
            })
            .catch(reject);
    });
}

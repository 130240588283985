import * as Endpoint from './endpoint';
import * as Query from 'query-string';

export default class ServicesAPI {
    static getCompany(companyId) {
        let parameters = {
            Id: companyId,
            IncludeBookingAgreements: true,
            IncludeWidgetSettings: true,
        };

        return Endpoint.get('/companies?' + Query.stringify(parameters));
    }

    static getCompanies(search) {
        let parameters = {
            Search: search || undefined,
            Take: 5,
        };

        return Endpoint.get('/companies?' + Query.stringify(parameters));
    }

    static getServices(companyId, search) {
        let parameters = {
            CompanyId: companyId,
            Active: true,
        };

        return Endpoint.get('/services?' + Query.stringify(parameters));
    }
}

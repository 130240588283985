import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { reducers } from './reducers/index';

export const createStoreWithInitialState = (initialState) => {
    // add the middlewares
    let middlewares = [];

    // add the middleware
    if (process.env.NODE_ENV !== 'production') {
        //middlewares.push(logger);
    }

    // apply the middleware
    let middleware = applyMiddleware(...middlewares);

    // add the redux dev tools
    if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    // create the store
    const _store = createStore(reducers, initialState ,middleware);

    /*
    persistStore(store, {blacklist: ['_routing']}, () => {
      console.log('rehydration complete')
    });
    */
   return _store;
}

// export
export const store = createStoreWithInitialState();
